// third-party
import { FormattedMessage } from 'react-intl';
import { FaCog, FaTags, FaMapMarkerAlt, FaCheckCircle, FaQuestionCircle, FaBolt, FaPercentage, FaBell, FaFileAlt } from 'react-icons/fa';

// ==============================|| MENU ITEMS - APPLICATIONS ||============================== //

const applications = {
  id: 'Configurations',
  title: <FormattedMessage id="Configurations" />,
  icon: FaCog,
  type: 'group',
  children: [
    {
      id: 'Configurations',
      title: <FormattedMessage id="Configurations" />,
      type: 'collapse',
      icon: FaTags,
      breadcrumbs: true,
      children: [
        {
          id: 'Category',
          title: <FormattedMessage id="Category" />,
          type: 'item',
          url: '/apps/category'
        },
        {
          id: 'Sub Category',
          title: <FormattedMessage id="Sub Category" />,
          type: 'item',
          url: '/apps/subcategory'
        },
        {
          id: 'Product Type',
          title: <FormattedMessage id="Product Type" />,
          type: 'item',
          url: '/apps/product-type'
        },
        {
          id: 'Attribute',
          title: <FormattedMessage id="Attribute" />,
          type: 'item',
          url: '/apps/variant'
        },
        {
          id: 'Attribute Value',
          title: <FormattedMessage id="Attribute Value" />,
          type: 'item',
          url: '/apps/variant-value'
        }
      ]
    },
    {
      id: 'Location',
      title: <FormattedMessage id="Location" />,
      type: 'collapse',
      icon: FaMapMarkerAlt,
      breadcrumbs: true,
      children: [
        {
          id: 'State',
          title: <FormattedMessage id="State" />,
          type: 'item',
          url: '/apps/state'
        },
        {
          id: 'City',
          title: <FormattedMessage id="City" />,
          type: 'item',
          url: '/apps/city'
        }
      ]
    },
    {
      id: 'Checklist',
      title: <FormattedMessage id="Checklist" />,
      type: 'collapse',
      icon: FaCheckCircle,
      breadcrumbs: true,
      children: [
        {
          id: 'list',
          title: <FormattedMessage id="list" />,
          type: 'item',
          url: '/checklist'
        },
        {
          id: 'manage',
          title: <FormattedMessage id="manage" />,
          type: 'item',
          url: '/checklist/manage'
        }
      ]
    },
    {
      id: 'FAQ',
      title: <FormattedMessage id="FAQ" />,
      type: 'collapse',
      icon: FaQuestionCircle,
      breadcrumbs: true,
      children: [
        {
          id: 'list',
          title: <FormattedMessage id="list" />,
          type: 'item',
          url: '/faq'
        },
        {
          id: 'manage',
          title: <FormattedMessage id="manage" />,
          type: 'item',
          url: '/faq/manage'
        }
      ]
    },
    {
      id: 'Charges',
      title: <FormattedMessage id="Charges" />,
      type: 'collapse',
      icon: FaBolt,
      breadcrumbs: true,
      children: [
        {
          id: 'list',
          title: <FormattedMessage id="list" />,
          type: 'item',
          url: '/apps/charges'
        },
        {
          id: 'manage',
          title: <FormattedMessage id="manage" />,
          type: 'item',
          url: '/apps/charges/manage'
        }
      ]
    },
    {
      id: 'Taxes',
      title: <FormattedMessage id="Taxes" />,
      type: 'collapse',
      icon: FaPercentage,
      breadcrumbs: true,
      children: [
        {
          id: 'list',
          title: <FormattedMessage id="list" />,
          type: 'item',
          url: '/apps/taxes/list-tax'
        }
      ]
    },
    {
      id: 'CMS',
      title: <FormattedMessage id="CMS" />,
      type: 'item',
      icon: FaFileAlt,
      url: '/cms'
    },
    {
      id: 'Notifications',
      title: <FormattedMessage id="Notifications" />,
      type: 'item',
      icon: FaBell,
      url: '/apps/notifications'
    }
  ]
};

export default applications;

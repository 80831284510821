import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer } from 'react';

// third-party
// import { Chance } from 'chance';
import jwtDecode from 'jwt-decode';

// reducer - state management
import { LOGIN, LOGOUT } from 'store/reducers/actions';
import authReducer from 'store/reducers/auth';

// project-imports
import Loader from 'components/Loader';
import axios from 'utils/axios';

// const chance = new Chance();

// constant
const initialState = {
  isLoggedIn: false,
  isInitialized: false,
  user: null
};

// const verifyToken = (serviceToken) => {
//   if (!serviceToken) {
//     return false;
//   }
//   const decoded = jwtDecode(serviceToken);

//   /**
//    * Property 'exp' does not exist on type '<T = unknown>(token: string, options?: JwtDecodeOptions | undefined) => T'.
//    */
//   return decoded.exp > Date.now() / 1000;
// };

const verifyToken = (refreshToken) => {
  if (!refreshToken) {
    return false;
  }

  try {
    const decoded = jwtDecode(refreshToken);
    if (decoded.exp <= Date.now() / 1000) {
      return false; 
    }
    return true; 
  } catch (error) {
    console.error('Error decoding refresh token:', error);
    return false;
  }
};

const setSession = (serviceToken, refreshToken, adminId, adminEmail) => {
  if (serviceToken) {
    localStorage.setItem('serviceToken', serviceToken);
    localStorage.setItem('refreshToken', refreshToken);
    localStorage.setItem('adminId', adminId);
    localStorage.setItem('adminEmail', adminEmail);
    // axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    axios.defaults.headers.common.Authorization = serviceToken;
    axios.defaults.headers.common.refreshToken = refreshToken;
  } else {
    localStorage.removeItem('serviceToken');
    localStorage.removeItem('adminId');
    localStorage.removeItem('adminEmail');
    delete axios.defaults.headers.common.Authorization;
    delete axios.defaults.headers.common.refreshToken;
  }
};

// ==============================|| JWT CONTEXT & PROVIDER ||============================== //

const JWTContext = createContext(null);

export const JWTProvider = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, initialState);

  useEffect(() => {
    const init = async () => {
      try {
        const serviceToken = localStorage.getItem('serviceToken');
        const refreshToken = localStorage.getItem('refreshToken');
        const adminId = localStorage.getItem('adminId');
        const adminEmail = localStorage.getItem('adminEmail');
        if (refreshToken && verifyToken(refreshToken)) {
          setSession(serviceToken, refreshToken, adminId, adminEmail);
          // const response = await axios.get('/api/account/me');
          // const { user } = response.data;

          dispatch({
            type: LOGIN,
            payload: {
              isLoggedIn: true
              // user
            }
          });
        } else {
          await logout();
          dispatch({
            type: LOGOUT
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: LOGOUT
        });
      }
    };

    init();
  }, []);

  const login = async (email, password) => {
    // const response = await axios.post('/api/account/login', { email, password });
    const response = await axios.post('/admin/login', { email, password });

    const serviceToken = response.data.result.data.token;
    const refreshToken = response.data.result.data.refreshToken;
    const user = {};
    user.id = response.data.result.data.id;
    user.name = response.data.result.data.name;
    user.email = response.data.result.data.email;
    setSession(serviceToken, refreshToken, user.id, user.email);
    dispatch({
      type: LOGIN,
      payload: {
        isLoggedIn: true,
        user
      }
    });
  };

  // const register = async (email, password, firstName, lastName) => {
  const register = async (name, email, password) => {
    // todo: this flow need to be recode as it not verified
    // const id = chance.bb_pin();
    // const response = await axios.post('/api/account/register', {
    const response = await axios.post('/admin/register', {
      // id,
      name,
      email,
      password
      // firstName,
      // lastName
    });
    let users = response.data;

    if (window.localStorage.getItem('users') !== undefined && window.localStorage.getItem('users') !== null) {
      const localUsers = window.localStorage.getItem('users');
      users = [
        ...JSON.parse(localUsers),
        {
          id,
          email,
          password,
          name: `${firstName} ${lastName}`
        }
      ];
    }

    window.localStorage.setItem('users', JSON.stringify(users));
  };

 const logout = async () => {
   const adminId = localStorage.getItem('adminId');
   try {
     await axios.post('/admin/logout', { id: adminId });
   } catch (error) {
     console.error('Logout API error:', error);
   } finally {
     localStorage.removeItem('refreshToken');
     setSession(null);
     dispatch({ type: LOGOUT });
   }
 };

  const resetPassword = async () => {};

  const updateProfile = () => {};

  if (state.isInitialized !== undefined && !state.isInitialized) {
    return <Loader />;
  }

  return <JWTContext.Provider value={{ ...state, login, logout, register, resetPassword, updateProfile }}>{children}</JWTContext.Provider>;
};

JWTProvider.propTypes = {
  children: PropTypes.node
};

export default JWTContext;
